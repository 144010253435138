// Manager to get different models.
// Types
import type { ModelsSchemaGroupItems } from "../_ReportsCreateEditQueryParams.types";
import type {
  PayloadObject,
  UndefinedNull,
} from "../../../../../types/common.types";
//FILTERS
export const onParseFieldName = (
  fieldName: string,
  model:
    | "Users"
    | "Enrollments"
    | "Institutions"
    | "Rotations"
    | "CaseLogs"
    | "Evaluations",
  isCustomField: boolean = false
) => {
  if (isCustomField) {
    return fieldName;
  }
  return parseField(
    fieldName.charAt(0).toUpperCase() +
      fieldName
        .slice(1)
        .split(/(?=[A-Z])/)
        .join(" "),
    model
  );
};
const parseField = (
  fieldName: string,
  model:
    | "Users"
    | "Enrollments"
    | "Institutions"
    | "Rotations"
    | "CaseLogs"
    | "Evaluations"
) =>
  fieldName.replace(
    /(Answer.answer|Patient.institution|Institution._id|Institution.type|Institution.name|Student.student Number|Rotation.enrollment|Rotation.institutions|Evaluation Scheduler.evaluation Template.name|Evaluation Answer Type|Start|At|._id|_id|.)/gi,
    (str: string) => {
      switch (str) {
        case "Answer.answer":
          return "Evaluation > Goals";
        case "Patient.institution":
        case "Institution._id":
          return "Clinic";
        case "Institution.type":
          return "Clinic Type";
        case "Institution.name":
          return "Clinic name";
        case "Student.student Number":
          return "Student Number";
        case "Rotation.institutions":
          return "Clinic";
        case "Rotation.enrollment":
          return "Enrollment";
        case "Evaluation Scheduler.evaluation Template.name":
          return "Evaluation Template Name";
        case "Evaluation Answer Type":
          return "Evaluation Type";
        case "Start":
          return "Begin";
        case "At":
          return "On";
        case "._id":
          return "";
        case "_id":
          if (model === "Institutions") {
            return "Clinics";
          } else {
            return "id";
          }
        case ".":
          return " ";
        default:
          return str;
      }
    }
  );
const onParseItemCustomField = (
  customField: {
    fieldType:
      | "string"
      | "number"
      | "date"
      | "boolean"
      | "dropdown"
      | "radiobutton"
      | "checkbox";
    label: string;
    name: string;
  },
  model?: string
): PayloadObject | UndefinedNull => {
  const { fieldType, label, name } = customField;
  let item = {};
  let fieldName = label;
  // NOTE: API does not allow this fieldName
  // if (model) {
  //   fieldName = `${label} ${model}`;
  // }
  switch (fieldType) {
    case "string":
      return {
        match: onCreateFilter("string", fieldName, [], true, true),
        options: onCreateOptions(null, true),
        fieldName,
        as: name,
        isCustomField: true,
      };
    case "number":
      return {
        ...item,
        match: onCreateFilter("number", fieldName, null, true, true),
        options: {
          selected: null,
        },
        fieldName,
        as: name,
        isCustomField: true,
      };
    case "date":
      return {
        ...item,
        match: onCreateFilter(
          "date",
          fieldName,
          { from: null, to: null },
          true,
          true
        ),
        fieldName,
        as: name,
        isCustomField: true,
      };
    case "boolean":
      return {
        match: onCreateFilter("boolean", fieldName, null, true, true),
        options: onCreateOptions(null, false),
        fieldName,
        as: name,
        isCustomField: true,
      };
    case "dropdown":
    case "radiobutton":
    case "checkbox":
      return {
        match: onCreateFilter("string", fieldName, [], true, true),
        options: onCreateOptions(null, false, []),
        fieldName,
        as: name,
        isCustomField: true,
        onlyProjection: true,
      };
    default:
      return null;
  }
};
export const onCreateFilter = (
  type: "string" | "date" | "number" | "boolean" | "id" | "evaluation-tag",
  fieldName: string,
  val: any,
  isDynamic: boolean = true,
  isCustomField: boolean = false
): PayloadObject | UndefinedNull => {
  switch (type) {
    case "date":
      return {
        type,
        fieldName,
        from: val.from,
        to: val.to,
        isDynamic,
        isCustomField,
      };
    case "number":
      return {
        type,
        fieldName,
        conditions: val,
        isDynamic,
        isCustomField,
      };
    default:
      return {
        type,
        fieldName,
        val,
        isDynamic,
        isCustomField,
      };
  }
};
export const onCreateOptions = (
  nameProp: string | UndefinedNull,
  allowAdditions: boolean,
  values: Array<{ key: string; value: string | boolean; text: string }> = []
): PayloadObject | UndefinedNull => {
  return {
    nameProp,
    allowAdditions,
    values,
  };
};
const onNestedFieldName = (
  fieldName: string,
  nestedFieldName:
    | "student"
    | "preceptor"
    | "evaluator"
    | "target"
    | "enrollment"
    | "institution"
    | "institutions"
    | "rotation"
    | "rotation.enrollment"
    | "rotation.institutions"
    | "answer"
    | "" = ""
) => {
  if (nestedFieldName) {
    return `${nestedFieldName}.${fieldName}`;
  }
  return fieldName;
};
const onParseAsNestedFieldName = (
  asFieldName: string,
  nestedFieldName:
    | "student"
    | "preceptor"
    | "evaluator"
    | "target"
    | "enrollment"
    | "rotation"
    | "rotation.enrollment"
    | "answer"
    | "" = ""
) => {
  if (nestedFieldName) {
    let parsedAsFieldName = asFieldName;
    if (
      nestedFieldName === "student" &&
      parsedAsFieldName === "Student Number"
    ) {
      parsedAsFieldName = "Number";
    }
    const parsedNestedFieldName = nestedFieldName.replace(
      /rotation.enrollment/g,
      "enrollment"
    );
    return `${
      parsedNestedFieldName.charAt(0).toUpperCase() +
      parsedNestedFieldName.slice(1)
    } ${parsedAsFieldName}`;
  }
  return asFieldName;
};
// MODELS
const onCreateItemsCustomFieldsReportSchema = (
  Items: PayloadObject,
  isUsersCustomFields: boolean = false
): PayloadObject[] => {
  let itemsCustomField = [];
  for (let itemKey in Items) {
    for (let i = 0; i < Items[itemKey].length; i++) {
      let itemCustomField = isUsersCustomFields
        ? onParseItemCustomField(Items[itemKey][i], itemKey)
        : onParseItemCustomField(Items[itemKey][i]);
      if (itemCustomField) {
        if (i === 0) {
          itemCustomField = {
            ...itemCustomField,
            section: itemKey,
          };
        }
        itemsCustomField.push(itemCustomField);
      }
    }
  }
  return itemsCustomField;
};
// <USERS>
const onCreateUsersGeneralReportSchema = (
  nested: "student" | "preceptor" | "evaluator" | "target" | "" = "",
  onlyProjectionRole: boolean = false
): PayloadObject[] => [
  {
    match: onCreateFilter("date", onNestedFieldName("createdAt", nested), {
      from: null,
      to: null,
    }),
    fieldName: onNestedFieldName("createdAt", nested),
    as: onParseAsNestedFieldName("Created On", nested),
  },
  {
    match: onCreateFilter("string", onNestedFieldName("role", nested), []),
    options: onCreateOptions("roles", false),
    fieldName: onNestedFieldName("role", nested),
    as: onParseAsNestedFieldName("Role", nested),
    onlyProjection: onlyProjectionRole,
  },
  {
    match: onCreateFilter("string", onNestedFieldName("email", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("email", nested),
    as: onParseAsNestedFieldName("Email", nested),
  },
  {
    match: onCreateFilter("string", onNestedFieldName("firstName", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("firstName", nested),
    as: onParseAsNestedFieldName("First Name", nested),
  },
  {
    match: onCreateFilter("string", onNestedFieldName("lastName", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("lastName", nested),
    as: onParseAsNestedFieldName("Last Name", nested),
  },
  {
    match: onCreateFilter("date", onNestedFieldName("birthdate", nested), {
      from: null,
      to: null,
    }),
    fieldName: onNestedFieldName("birthdate", nested),
    as: onParseAsNestedFieldName("Birthdate", nested),
  },
  {
    match: onCreateFilter("string", onNestedFieldName("gender", nested), []),
    options: onCreateOptions("genders", false),
    fieldName: onNestedFieldName("gender", nested),
    as: onParseAsNestedFieldName("Gender", nested),
  },
  {
    match: onCreateFilter("string", onNestedFieldName("addresses", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("addresses", nested),
    as: onParseAsNestedFieldName("Address information", nested),
    onlyProjection: true,
  },
];
const onCreateUsersStudentsReportSchema = (
  nested: "student" | "evaluator" | "target" | "" = "",
  section: "Student" | "" = ""
): PayloadObject[] => [
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("studentNumber", nested),
      []
    ),
    options: onCreateOptions(null, true),
    section,
    fieldName: onNestedFieldName("studentNumber", nested),
    as: onParseAsNestedFieldName("Student Number", nested),
  },
  {
    match: onCreateFilter(
      "number",
      onNestedFieldName("matriculationYear", nested),
      null
    ),
    options: {
      selected: null,
    },
    fieldName: onNestedFieldName("matriculationYear", nested),
    as: onParseAsNestedFieldName("Matriculation year", nested),
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "number",
      onNestedFieldName("expectedGraduationYear", nested),
      null
    ),
    options: {
      selected: null,
    },
    fieldName: onNestedFieldName("expectedGraduationYear", nested),
    as: onParseAsNestedFieldName("Expected graduation year", nested),
    onlyProjection: true,
  },
];
export const onCreateUsersReportSchema = (
  Users: PayloadObject | UndefinedNull
): ModelsSchemaGroupItems[] => {
  let usersSchema = [
    {
      group: "User",
      items: [
        ...onCreateUsersGeneralReportSchema(),
        ...onCreateUsersStudentsReportSchema("", "Student"),
        {
          match: onCreateFilter("string", "jobTitle", []),
          options: onCreateOptions(null, true),
          section:
            "Super School Admin, School Admin, Faculty, Clinic Admin, Preceptor",
          fieldName: "jobTitle",
          as: "Job title",
          onlyProjection: true,
        },
      ],
    },
  ];
  if (Users) {
    usersSchema.push({
      group: "Custom Fields",
      items: onCreateItemsCustomFieldsReportSchema(Users, true),
    });
  }
  return usersSchema;
};
// </USERS>
// <ENROLLMENTS>
const onCreateEnrollmentsGeneralReportSchema = (
  nested: "enrollment" | "rotation.enrollment" | "" = "",
  section: "Enrollment" | "" = ""
): PayloadObject[] => [
  {
    match: onCreateFilter("date", onNestedFieldName("createdAt", nested), {
      from: null,
      to: null,
    }),
    section,
    fieldName: onNestedFieldName("createdAt", nested),
    as: onParseAsNestedFieldName("Created On", nested),
  },
  {
    match: onCreateFilter("date", onNestedFieldName("start", nested), {
      from: null,
      to: null,
    }),
    fieldName: onNestedFieldName("start", nested),
    as: onParseAsNestedFieldName("Begin", nested),
  },
  {
    match: onCreateFilter("date", onNestedFieldName("end", nested), {
      from: null,
      to: null,
    }),
    fieldName: onNestedFieldName("end", nested),
    as: onParseAsNestedFieldName("End", nested),
  },
];
const onCreateEnrollmentsProgramsReportSchema = (
  nested: "enrollment" | "rotation.enrollment" | "" = ""
): PayloadObject[] => [
  {
    match: onCreateFilter("id", onNestedFieldName("program._id", nested), []),
    options: onCreateOptions("programs", false),
    fieldName: onNestedFieldName("program.name", nested),
    as: onParseAsNestedFieldName("Program", nested),
  },
];
const onCreateEnrollmentsCohortsReportSchema = (
  nested: "enrollment" | "rotation.enrollment" | "" = ""
): PayloadObject[] => [
  {
    match: onCreateFilter("id", onNestedFieldName("cohort._id", nested), []),
    options: onCreateOptions("cohorts", false),
    fieldName: onNestedFieldName("cohort.name", nested),
    as: onParseAsNestedFieldName("Cohort", nested),
  },
];
export const onCreateEnrollmentsReportSchema = (): ModelsSchemaGroupItems[] => {
  let enrollmentsSchema = [
    {
      group: "Enrollment",
      items: [
        ...onCreateEnrollmentsGeneralReportSchema(),
        ...onCreateEnrollmentsProgramsReportSchema(),
        ...onCreateEnrollmentsCohortsReportSchema(),
      ],
    },
  ];
  enrollmentsSchema.push({
    group: "Student",
    items: [
      ...onCreateUsersGeneralReportSchema("student", true),
      ...onCreateUsersStudentsReportSchema("student"),
    ],
  });
  return enrollmentsSchema;
};
// </ENROLLMENTS>
// <INSTITUTIONS>
const onCreateInstitutionsGeneralReportSchema = (
  nested:
    | "institution"
    | "institutions"
    | "rotation.institutions"
    | "target"
    | "" = ""
): PayloadObject[] => [
  {
    match: onCreateFilter("date", onNestedFieldName("createdAt", nested), {
      from: null,
      to: null,
    }),
    fieldName: onNestedFieldName("createdAt", nested),
    as: "Clinic Created On",
  },
  {
    match: onCreateFilter("id", onNestedFieldName("_id", nested), []),
    options: onCreateOptions("institutions", false),
    fieldName: onNestedFieldName("_id", nested),
    as: "Clinics",
    onlyMatch: true,
  },
  {
    match: onCreateFilter("string", onNestedFieldName("name", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("name", nested),
    as: "Clinic Name",
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("description", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("description", nested),
    as: "Clinic Description",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("id", onNestedFieldName("type", nested), []),
    options: onCreateOptions("institutionsTypes", false),
    fieldName: onNestedFieldName("type", nested),
    as: "Clinic Type",
  },
  {
    match: onCreateFilter("string", onNestedFieldName("website", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("website", nested),
    as: "Clinic Website",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", onNestedFieldName("addresses", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("addresses", nested),
    as: "Clinic Address information",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("scheduleInfo", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("scheduleInfo", nested),
    as: "Clinic Hours",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", onNestedFieldName("reportTo", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("reportTo", nested),
    as: "Clinic Reporting To",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("equipmentInfo", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("equipmentInfo", nested),
    as: "Clinic Equipment Info",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("parkingInfo", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("parkingInfo", nested),
    as: "Clinic Parking Info",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("securityInfo", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("securityInfo", nested),
    as: "Clinic Security Info",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("studentsInfo", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("studentsInfo", nested),
    as: "Clinic Student Info",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", onNestedFieldName("hotelInfo", nested), []),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("hotelInfo", nested),
    as: "Clinic Hotel Info",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("foodAvailability", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("foodAvailability", nested),
    as: "Clinic Food Availability",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("travelReimbursement", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("travelReimbursement", nested),
    as: "Clinic Travel Reimbursement",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("otherConsiderations", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("otherConsiderations", nested),
    as: "Clinic Other Considerations",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("contract.date", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("contract.date", nested),
    as: "Clinic Contract Date",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("contract.expiration", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("contract.expiration", nested),
    as: "Clinic Contract Expiration",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("contract.terminationTerms", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("contract.terminationTerms", nested),
    as: "Clinic contract Termination Terms",
    onlyProjection: true,
  },
];
export const onCreateInstitutionsReportSchema = (
  Institutions: PayloadObject | UndefinedNull
): ModelsSchemaGroupItems[] => {
  let institutionsSchema = [
    {
      group: "Clinic",
      items: onCreateInstitutionsGeneralReportSchema(),
    },
  ];
  if (Institutions) {
    institutionsSchema.push({
      group: "Custom Fields",
      items: onCreateItemsCustomFieldsReportSchema(Institutions),
    });
  }
  return institutionsSchema;
};
// </INSTITUTIONS>
// <ROTATIONS>
const onCreateRotationsGeneralReportSchema = (
  nested: "rotation" | "" = ""
): PayloadObject[] => [
  {
    match: onCreateFilter("date", onNestedFieldName("createdAt", nested), {
      from: null,
      to: null,
    }),
    fieldName: onNestedFieldName("createdAt", nested),
    as: onParseAsNestedFieldName("Created On", nested),
  },
  {
    match: onCreateFilter("date", onNestedFieldName("start", nested), {
      from: null,
      to: null,
    }),
    fieldName: onNestedFieldName("start", nested),
    as: onParseAsNestedFieldName("Begin", nested),
  },
  {
    match: onCreateFilter("date", onNestedFieldName("end", nested), {
      from: null,
      to: null,
    }),
    fieldName: onNestedFieldName("end", nested),
    as: onParseAsNestedFieldName("End", nested),
  },
  {
    match: onCreateFilter("id", onNestedFieldName("period._id", nested), []),
    options: onCreateOptions("periods", false),
    fieldName: onNestedFieldName("period.name", nested),
    as: onParseAsNestedFieldName("Period", nested),
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("rotationNumber", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("rotationNumber", nested),
    as: "Rotation ID",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", onNestedFieldName("status", nested), []),
    options: onCreateOptions("rotationsStatus", false),
    fieldName: onNestedFieldName("status", nested),
    as: "Rotation Status",
  },
];
export const onCreateRotationsReportSchema = (): ModelsSchemaGroupItems[] => {
  let rotationsSchema = [
    {
      group: "Rotation",
      items: onCreateRotationsGeneralReportSchema(),
    },
  ];
  rotationsSchema.push({
    group: "Student",
    items: [
      ...onCreateUsersGeneralReportSchema("student", true),
      ...onCreateUsersStudentsReportSchema("student"),
    ],
  });
  rotationsSchema.push({
    group: "Clinic",
    items: onCreateInstitutionsGeneralReportSchema("institutions"),
  });
  rotationsSchema.push({
    group: "Enrollment",
    items: [
      ...onCreateEnrollmentsGeneralReportSchema("enrollment"),
      ...onCreateEnrollmentsProgramsReportSchema("enrollment"),
      ...onCreateEnrollmentsCohortsReportSchema("enrollment"),
    ],
  });
  return rotationsSchema;
};
// </ROTATIONS>
// <CASE LOGS>
const onCreateCaseLogsGeneralReportSchema = (): PayloadObject[] => [
  {
    match: onCreateFilter("date", "createdAt", { from: null, to: null }),
    fieldName: "createdAt",
    as: "Created On",
  },
  {
    match: onCreateFilter("string", "caseLogNumber", []),
    options: onCreateOptions(null, true),
    fieldName: "caseLogNumber",
    as: "Case Log ID",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "status", []),
    options: onCreateOptions("caseLogsStatus", false),
    fieldName: "status",
    as: "Case Log Status",
  },
  {
    match: onCreateFilter("number", "patient.age", null),
    options: {
      selected: null,
    },
    fieldName: "patient.age",
    as: "Patient Age",
  },
  {
    match: onCreateFilter("string", "patient.typeAge", []),
    options: onCreateOptions("patientTypeAges", false),
    fieldName: "patient.typeAge",
    as: "Patient Age Type",
  },
  {
    match: onCreateFilter("string", "patient.gender", []),
    options: onCreateOptions("patientGenders", false),
    fieldName: "patient.gender",
    as: "Patient Gender",
  },
  {
    match: onCreateFilter("string", "patient.race", []),
    options: onCreateOptions("patientRaces", false),
    fieldName: "patient.race",
    as: "Patient Race",
  },
  {
    match: onCreateFilter("string", "patient.firstLanguage", []),
    options: onCreateOptions("caseLogsLanguagesList", false),
    fieldName: "patient.firstLanguage",
    as: "Patient First Language",
  },
  {
    match: onCreateFilter("string", "patient.insurance", []),
    options: onCreateOptions("caseLogsInsurancesList", false),
    fieldName: "patient.insurance",
    as: "Patient Insurance",
  },
  {
    match: onCreateFilter("string", "patient.institutionRecordNumber", []),
    options: onCreateOptions(null, true),
    fieldName: "patient.institutionRecordNumber",
    as: "Clinic Record Number",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "reasonForVisit", []),
    options: onCreateOptions("caseLogsReasonForVisits", false),
    fieldName: "reasonForVisit",
    as: "Reason for Visit",
  },
  {
    match: onCreateFilter("number", "minutesWithPatient", null),
    options: {
      selected: null,
    },
    fieldName: "minutesWithPatient",
    as: "Time with Patient",
  },
  {
    match: onCreateFilter("number", "minutesWithPreceptor", null),
    options: {
      selected: null,
    },
    fieldName: "minutesWithPreceptor",
    as: "Consult with Preceptor",
  },
  {
    match: onCreateFilter("date", "appointmentDate", {
      from: null,
      to: null,
    }),
    fieldName: "appointmentDate",
    as: "Appointment Date",
  },
  {
    match: onCreateFilter("string", "preceptorComments", []),
    options: onCreateOptions(null, true),
    fieldName: "preceptorComments",
    as: "Preceptor Comments",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "chiefComplaint", []),
    options: onCreateOptions(null, true),
    fieldName: "chiefComplaint",
    as: "Chief Complaint",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "medicationTaken", []),
    options: onCreateOptions(null, true),
    fieldName: "medicationTaken",
    as: "Medication Taken",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "prescriptionThisVisit", []),
    options: onCreateOptions(null, true),
    fieldName: "prescriptionThisVisit",
    as: "Prescription This Visit",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "psychosocialTopics", []),
    options: onCreateOptions(null, true),
    fieldName: "psychosocialTopics",
    as: "Psychosocial Topics",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "otherPsychosocialTopic", []),
    options: onCreateOptions(null, true),
    fieldName: "otherPsychosocialTopic",
    as: "Other Psychosocial Topic",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "notes", []),
    options: onCreateOptions(null, true),
    fieldName: "notes",
    as: "Student Notes",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      "diagnosesAndProcedures.toothReference",
      []
    ),
    section: "Diagnoses and Procedures",
    options: onCreateOptions("caseLogsTeethList", false),
    fieldName: "diagnosesAndProcedures.toothReference",
    as: "Tooth Reference",
  },
  {
    match: onCreateFilter(
      "string",
      "diagnosesAndProcedures.diagnosticDiscipline",
      []
    ),
    options: onCreateOptions("caseLogsDisciplinesListForDiagnostics", false),
    fieldName: "diagnosesAndProcedures.diagnosticDiscipline",
    as: "Discipline for Diagnosis",
  },
  {
    match: onCreateFilter("string", "diagnosesAndProcedures.diagnostic", []),
    options: onCreateOptions("caseLogsDiagnosticsList", false),
    fieldName: "diagnosesAndProcedures.diagnostic",
    as: "Diagnosis",
  },
  {
    match: onCreateFilter(
      "string",
      "diagnosesAndProcedures.diagnosticDescription",
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: "diagnosesAndProcedures.diagnosticDescription",
    as: "Diagnosis Description",
  },
  {
    match: onCreateFilter(
      "string",
      "diagnosesAndProcedures.procedureDiscipline",
      []
    ),
    options: onCreateOptions("caseLogsDisciplinesListForProcedures", false),
    fieldName: "diagnosesAndProcedures.procedureDiscipline",
    as: "Discipline for Procedure",
  },
  {
    match: onCreateFilter("string", "diagnosesAndProcedures.uniqueId", []),
    options: onCreateOptions(null, true),
    fieldName: "diagnosesAndProcedures.uniqueId",
    as: "Procedure ID",
    onlyProjection: true,
  },
  {
    match: onCreateFilter("string", "diagnosesAndProcedures.procedure", []),
    options: onCreateOptions("caseLogsProceduresList", false),
    fieldName: "diagnosesAndProcedures.procedure",
    as: "Procedure",
  },
  {
    match: onCreateFilter(
      "string",
      "diagnosesAndProcedures.procedureDescription",
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: "diagnosesAndProcedures.procedureDescription",
    as: "Procedure Description",
  },
  {
    match: onCreateFilter(
      "string",
      "diagnosesAndProcedures.procedureStatus",
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: "diagnosesAndProcedures.procedureStatus",
    as: "Procedure Status",
  },
  {
    match: onCreateFilter("number", "diagnosesAndProcedures.cpu", null),
    options: {
      selected: null,
    },
    fieldName: "diagnosesAndProcedures.cpu",
    as: "Procedure CPU",
  },
];
export const onCreateCaseLogsReportSchema = (
  CaseLogs: PayloadObject | UndefinedNull
): ModelsSchemaGroupItems[] => {
  let caseLogsSchema = [
    {
      group: "Case Log",
      items: onCreateCaseLogsGeneralReportSchema(),
    },
  ];
  caseLogsSchema.push({
    group: "Student",
    items: [
      ...onCreateUsersGeneralReportSchema("student", true),
      ...onCreateUsersStudentsReportSchema("student"),
    ],
  });
  caseLogsSchema.push({
    group: "Preceptor",
    items: [
      ...onCreateUsersGeneralReportSchema("preceptor", true),
      {
        match: onCreateFilter("string", "preceptor.jobTitle", []),
        options: onCreateOptions(null, true),
        fieldName: "preceptor.jobTitle",
        as: "Preceptor Job title",
        onlyProjection: true,
      },
    ],
  });
  caseLogsSchema.push({
    group: "Clinic",
    items: onCreateInstitutionsGeneralReportSchema("institution"),
  });
  caseLogsSchema.push({
    group: "Rotation",
    items: [
      ...onCreateRotationsGeneralReportSchema("rotation"),
      ...onCreateEnrollmentsGeneralReportSchema(
        "rotation.enrollment",
        "Enrollment"
      ),
      ...onCreateEnrollmentsProgramsReportSchema("rotation.enrollment"),
      ...onCreateEnrollmentsCohortsReportSchema("rotation.enrollment"),
    ],
  });
  if (CaseLogs) {
    caseLogsSchema.push({
      group: "Custom Fields",
      items: onCreateItemsCustomFieldsReportSchema(CaseLogs),
    });
  }
  return caseLogsSchema;
};
// </CASE LOGS>
// <EVALUATIONS>
const onCreateEvaluationsGeneralReportSchema = (): PayloadObject[] => [
  {
    match: onCreateFilter("date", "createdAt", { from: null, to: null }),
    fieldName: "createdAt",
    as: "Created On",
  },
  {
    match: onCreateFilter("string", "evaluationNumber", []),
    options: onCreateOptions(null, true),
    fieldName: "evaluationNumber",
    as: "Evaluation No",
  },
  {
    match: onCreateFilter("string", "evaluationType", []),
    options: onCreateOptions("evaluationType", false),
    fieldName: "evaluationType",
    as: "Evaluation Type",
  },
  {
    match: onCreateFilter("string", "status", []),
    options: onCreateOptions("evaluationsStatus", false),
    fieldName: "status",
    as: "Evaluation Status",
  },
  {
    match: onCreateFilter("string", "evaluationScheduler.name", []),
    options: onCreateOptions(null, true),
    fieldName: "evaluationScheduler.name",
    as: "Evaluation Scheduler",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      "evaluationScheduler.evaluationTemplate.name",
      []
    ),
    options: onCreateOptions("evaluationTemplates", false),
    fieldName: "evaluationScheduler.evaluationTemplate.name",
    as: "Evaluation Template",
  },
  {
    match: onCreateFilter("evaluation-tag", "answer.answer", []),
    options: onCreateOptions("evaluationsAnswer", false),
    fieldName: "answer.answer.options.tags",
    as: "Evaluation > Goals",
  },
];
const onCreateEvaluationsAnswersGeneralReportSchema = (
  nested: "answer" | "" = ""
): PayloadObject[] => [
  {
    match: onCreateFilter("date", onNestedFieldName("createdAt", nested), {
      from: null,
      to: null,
    }),
    section: "Answer",
    fieldName: onNestedFieldName("createdAt", nested),
    as: onParseAsNestedFieldName("Created On", nested),
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("evaluationAnswerNumber", nested),
      []
    ),
    options: onCreateOptions(null, false),
    fieldName: onNestedFieldName("evaluationAnswerNumber", nested),
    as: "Answer No",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("answer.type", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("answer.type", nested),
    as: "Question Type",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("answer.question", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("answer.question", nested),
    as: "Question or Text",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("answer.answer", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("answer.answer", nested),
    as: "Response",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("answer.header", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("answer.header", nested),
    as: "Single/Multiple Choice > Question or Text",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("answer.options.header", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("answer.options.header", nested),
    as: "Single/Multiple Choice > Option",
    onlyProjection: true,
  },
  {
    match: onCreateFilter(
      "string",
      onNestedFieldName("answer.options.selected", nested),
      []
    ),
    options: onCreateOptions(null, true),
    fieldName: onNestedFieldName("answer.options.selected", nested),
    as: "Single/Multiple Choice > Option Selected",
    onlyProjection: true,
  },
];
export const onCreateEvaluationsReportSchema = (): ModelsSchemaGroupItems[] => {
  let evaluationsSchema = [
    {
      group: "Evaluation",
      items: [
        ...onCreateEvaluationsGeneralReportSchema(),
        ...onCreateEvaluationsAnswersGeneralReportSchema("answer"),
      ],
    },
  ];
  evaluationsSchema.push({
    group: "Clinic",
    items: onCreateInstitutionsGeneralReportSchema("rotation.institutions"),
  });
  evaluationsSchema.push({
    group: "Evaluator",
    items: [
      ...onCreateUsersGeneralReportSchema("evaluator"),
      ...onCreateUsersStudentsReportSchema("evaluator", "Student"),
      {
        match: onCreateFilter("string", "evaluator.jobTitle", []),
        options: onCreateOptions(null, true),
        section:
          "Super School Admin, School Admin, Faculty, Clinic Admin, Preceptor",
        fieldName: "evaluator.jobTitle",
        as: "Evaluator Job title",
        onlyProjection: true,
      },
    ],
  });
  evaluationsSchema.push({
    group: "Target",
    items: [
      ...onCreateUsersGeneralReportSchema("target"),
      ...onCreateUsersStudentsReportSchema("target", "Student"),
      {
        match: onCreateFilter("string", "target.jobTitle", []),
        options: onCreateOptions(null, true),
        section:
          "Super School Admin, School Admin, Faculty, Clinic Admin, Preceptor",
        fieldName: "target.jobTitle",
        as: "Target Job title",
        onlyProjection: true,
      },
    ],
  });
  evaluationsSchema.push({
    group: "Enrollment",
    items: [
      ...onCreateEnrollmentsGeneralReportSchema("rotation.enrollment"),
      ...onCreateEnrollmentsProgramsReportSchema("rotation.enrollment"),
      ...onCreateEnrollmentsCohortsReportSchema("rotation.enrollment"),
    ],
  });
  return evaluationsSchema;
};
// </EVALUATIONS>
